/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        // JavaScript to be fired on the about us page
        // $("#demo").on("hide.bs.collapse", function(){
        //   $(".btn").html('<span class="glyphicon glyphicon-collapse-down"></span> Open');
        // });
        // $("#demo").on("show.bs.collapse", function(){
        //   $(".btn").html('<span class="glyphicon glyphicon-collapse-up"></span> Close');
        // });

        // var search = {
        //   init:function(){
        //       search.content = $('.search-container');
        //       search.active = $('.btn-search');
        //       search.active.on({
        //           click:function(){
        //               search.mostrar();
        //               $('body').toggleClass('scroll-none');
        //           },
        //       });
        //   },

        //   mostrar:function(){
        //       search.content.fadeToggle();
        //   },
        // }

        $(".btn-search").click(function() {
          $(".search-container").fadeToggle();
        });
      

        
        $("#btn-homepage-close").click(function(){
          $(".homepage-preview").hide(10);
        });
        
        
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

        $('.owl-carousel').owlCarousel({
          loop:true,
          margin:0,
          nav:true,
          responsive:{
            0:{
              items:1
            },
            600:{
              items:1
            },
            1000:{
              items:1
            }
          }
        });

        $( ".owl-prev").html('<img class="owlprev" src="http://niuh.mx/wp-content/themes/niuh/dist/images/icons/arrow_left.png">');
        $( ".owl-next").html('<img class="owlnext" src="http://niuh.mx/wp-content/themes/niuh/dist/images/icons/arrow_right.png">');

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'mi_cuenta': {
      init: function() {
        // JavaScript to be fired on the about us page

        $('.owl-carousel').owlCarousel({
          loop:true,
          margin:0,
          nav:true,
          lazyLoad: true,
          item: 1,
          autoplay: true,
          autoplayTimeout: 3000,
          dots:false,
          responsive:{
            0:{
              items:1
            },
            600:{
              items:1
            },
            1000:{
              items:1
            }
          }
        });
        
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
